<template>
  <div class="login d-flex justify-content-center">
    <div class="areawrap confirm-wrap">
      <template v-if="step === 1">
        <h2 class="mb-4">
          Confirm Personal Information
        </h2>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Full Name"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.owner_name.$anyError : null"
            >
              <b-form-input v-model="form.owner_name" placeholder="Enter Full Name" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.owner_name.required : null">
                full name is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Role" label-class="text-primary" class="mb-6">
              Owner
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.owner_email.$anyError : null"
            >
              <b-form-input v-model="form.owner_email" placeholder="Enter Email" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.owner_email.required : null">
                email is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Phone Extension">
              <b-form-input v-model="form.owner_extension" placeholder="Default" />
            </b-form-group>
          </b-col>  <b-col cols="4">
            <b-form-group label="Mobile">
              <b-form-input v-model="form.owner_mobile" placeholder="Default" />
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template v-if="step === 2">
        <h2 class="mb-4">
          Comfirm Corporate Information
        </h2>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Company Name"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_name.$anyError : null"
            >
              <b-form-input v-model="form.corporate_name" placeholder="Enter Company Name" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_name.required : null">
                company Name is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Street Address"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_address.$anyError : null"
            >
              <b-form-input v-model="form.corporate_address" placeholder="Enter Address" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_address.required : null">
                Street address is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <div class="mb-4">
              <b-form-group
                label="Country"
                class="mb-6"
                :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
              >
                <b-form-select
                  v-model="form.corporate_country"
                  :options="countryList"
                  :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
                  @change="onCountryChange"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Select Country
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_country.required : null">
                  country is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="mb-4">
              <b-form-group
                label="City"
                class="mb-6"
                :state="$v.form.$anyDirty ? !$v.form.corporate_city.$anyError : null"
              >
                <b-form-input v-model="form.corporate_city" placeholder="Enter City" />
                <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_city.required : null">
                  city is required
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Telephone"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_telphone.$anyError : null"
            >
              <b-form-input v-model="form.corporate_telphone" placeholder="Enter Telephone" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_telphone.required : null">
                telephone is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <template v-if="step === 3">
        <h2 class="mb-4">
          Add Contact
        </h2>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Full Name"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.contact_name.$anyError : null"
            >
              <b-form-input v-model="form.contact_name" placeholder="Enter Full Name" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.contact_name.required : null">
                full name is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.contact_email.$anyError : null"
            >
              <b-form-input v-model="form.contact_email" placeholder="Enter Email" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.contact_email.required : null">
                email is required
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Phone Extension">
              <b-form-input v-model="form.contact_extension" placeholder="Default" />
            </b-form-group>
          </b-col>
        </b-row>
      </template>

      <div class="text-right mt-6">
        <b-button variant="primary" class="btn-width" @click="nextStep">
          CONFIRM
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import countryList, { getCountryCode } from '@/common/countryList'
import { getUserProfileData } from '@/api/user'
import { updatePartnerInitData } from '@/api/partner'

export default {
  name: 'Login',
  data () {
    return {
      step: 1,
      form: {
        owner_name: '',
        owner_email: '',
        owner_extension: '',
        owner_mobile: '',
        corporate_name: '',
        corporate_country: null,
        corporate_city: null,
        corporate_address: '',
        corporate_telphone: '',
        contact_name: '',
        contact_email: '',
        contact_extension: ''
      }
    }
  },
  validations () {
    return {
      form: {
        owner_name: { required },
        owner_email: { required },
        corporate_name: { required },
        corporate_country: { required },
        corporate_city: { required },
        corporate_address: { required },
        corporate_telphone: { required },
        contact_name: { required },
        contact_email: { required }
      },

      step1: [
        'form.owner_name',
        'form.owner_email'
      ],
      step2: [
        'form.corporate_name',
        'form.corporate_country',
        'form.corporate_city',
        'form.corporate_address',
        'form.corporate_telphone'
      ],
      step3: [
        'form.contact_name',
        'form.contact_email'
      ]
    }
  },
  computed: {
    countryList () {
      return countryList
    }
  },
  created () {
    this.getUserProfileData()
  },
  methods: {
    getUserProfileData () {
      getUserProfileData().then(res => {
        this.form = res.data.data
      })
    },
    updatePartnerInitData () {
      updatePartnerInitData(this.form).then(() => {
        this.$router.push({ name: 'Dashboard' })
      })
    },
    onCountryChange (value) {
      this.form.corporate_telphone = `+${getCountryCode(value)}`
    },
    nextStep () {
      const stepName = `step${this.step}`
      this.$v[stepName].$touch()
      if (this.$v[stepName].$anyError) return
      this.$v.$reset()
      if (this.step === 3) {
        this.updatePartnerInitData()
      } else {
        this.step += 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recaptcha {
  transform: scale(1.05);
}
.confirm-wrap {
  width: 26rem;
}
</style>
